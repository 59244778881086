<!-- https://codesandbox.io/s/vue-countdown-b6k8k -->
<template>
    <div class="countdown">
        <div class="row g-0">
            <div v-if="days" class="col countdown__block py-sm-3 py-md-4--5 rounded">
                <span class="countdown__digit">{{ days | twoDigits }}</span>
                <span class="countdown__text">Jours</span>
            </div>
            <div class="col countdown__block py-sm-3 py-md-4--5 rounded">
                <span class="countdown__digit">{{ hours | twoDigits }}</span>
                <span class="countdown__text">Heures</span>
            </div>
            <div class="col countdown__block py-sm-3 py-md-4--5 rounded">
                <span class="countdown__digit">{{ minutes | twoDigits }}</span>
                <span class="countdown__text">Minutes</span>
            </div>
            <div class="col countdown__block py-sm-3 py-md-4--5 rounded">
                <span class="countdown__digit">{{ seconds | twoDigits }}</span>
                <span class="countdown__text">Secondes</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        date: null,
    },
    data() {
        return {
            now: Math.trunc(new Date().getTime() / 1000),
            event: this.date,
            finish: false,
        };
    },
    mounted() {
        const _self = this;
        window.setInterval(() => {
            this.now = Math.trunc(new Date().getTime() / 1000);
            if (!this.finish && this.calculatedDate - this.now <= 0) {
                _self.finish = true;
                _self.$emit("onFinish");
            }
        }, 1000);
    },
    computed: {
        secondCount() {
            return this.calculatedDate - this.now;
        },
        calculatedDate() {
            return Math.trunc(Date.parse(this.event) / 1000);
        },
        seconds() {
            if (this.secondCount < 0) return 0;
            return this.secondCount % 60;
        },
        minutes() {
            if (this.secondCount < 0) return 0;
            return Math.trunc(this.secondCount / 60) % 60;
        },
        hours() {
            if (this.secondCount < 0) return 0;
            return Math.trunc(this.secondCount / 60 / 60) % 24;
        },
        days() {
            if (this.secondCount < 0) return 0;
            return Math.trunc(this.secondCount / 60 / 60 / 24);
        },
    },
    filters: {
        twoDigits(value) {
            if (value.toString().length <= 1) {
                return "0" + value.toString();
            }
            return value.toString();
        },
    },
};
</script>
<style lang="scss">
$countdown-text-color: #fff;

.countdown {
    &__block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $countdown-text-color;
        padding: 1rem;
        margin: 0 3px;
        border: 2px solid $countdown-text-color;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    &__digit {
        font-size: 10vw;
        line-height: 1;
        margin-bottom: 2px;
        font-family: "AvenirLT85";

        @media (min-width: 980px) {
            font-size: 6rem;
        }
    }

    &__text {
        display: inline-block;
        text-transform: uppercase;
        font-size: 3vw;

        @media (min-width: 980px) {
            font-size: 1.5rem;
        }
    }
}
</style>

<!--
// https://codesandbox.io/s/vue-countdown-b6k8k
// utilisation
<Countdown :date="end" @onFinish="finish()"></Countdown> 

// declaration
<script>
import Countdown from "./Countdown.vue";

export default {
    components: {
        Countdown,
    },
    data() {
        return {
            end: new Date("2023-02-07T00:00:00"),
        };
    },
    methods: {
        finish() {
            console.log("finish");
        },
    },
};
</script>
-->
