<template>
    <div class="home">
        <div class="position-relative">
            <img src="../assets/img/home-01.png" class="mw-100 home__img" alt="" />
            <router-link to="/inscription" class="home__btn-register btn btn-primary">
                <span>JE M'INSCRIS</span>
            </router-link>
        </div>

        <NavigationLinks class="mt-5 mb-4" />

        <div class="text-center my-4 arial-rounded home__date">MERCREDI 27 MARS 2024</div>

        <MyCountdown :date="countdown_end" class="my-2" />

        <div class="text-center my-5">
            <div class="fs-4 px-xl-5--5">
                Le succès de la première édition de LADYj. Tech nous conduit cette année à renouveler l'expérience dans
                une deuxième édition riche en échanges !
            </div>
            <div class="fs-2 arial-rounded my-4">Cette année, LADYj.Tech adopte un nouveau format</div>
        </div>

        <div class="row home__programme">
            <div class="col-12 col-lg d-flex flex-column align-items-center px-3 px-md-5 px-xl-5--5">
                <span class="home__programme__title-1">DIJ'INNOV</span>
                <span class="home__programme__title-2">CONNECT</span>
                <div class="separation bg-green-light"></div>

                <span class="mb-4 fs-2">De 15H à 17H</span>

                <div class="d-flex flex-column align-items-center text-center fs-4">
                    <span class="my-3">
                        Séquence 100 % dédiée aux collaborations de R&D et
                        <span class="text-nowrap">d'innovation ! </span>
                    </span>
                    <span class="my-3">En un seul lieu, un temps privilégié pour :</span>
                    <span class="my-3">
                        <i class="fa-solid fa-arrow-right me-2"></i>
                        <span>découvrir le potentiel de la recherche publique de notre territoire.</span>
                    </span>
                    <span class="my-3">
                        <i class="fa-solid fa-arrow-right me-2"></i>
                        <span>identifier et échanger avec vos futurs partenaires innovation.</span>
                    </span>
                    <span class="my-3">
                        <i class="fa-solid fa-arrow-right me-2"></i>
                        <span>
                            approfondir votre connaissance des dispositifs de financements R&D, avec un focus sur le
                            Programme Cifre et le dispositif ICE.
                        </span>
                    </span>
                    <span class="mt-3">Stands, présentation de dispositifs, RDV personnalisés…</span>
                    <span>Tout pour innover en convivialité !</span>
                </div>

                <router-link to="/inscription" class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-primary">
                    JE M'INSCRIS
                </router-link>
                <router-link to="/opportunites-laboratoires" class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-green-light">
                    DÉCOUVRIR LES OPPORTUNITÉS
                    <br />
                    DES LABORATOIRES
                </router-link>
                <router-link to="/depot-problematique" class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-green-dark">
                    ENTREPRISES, DÉPOSEZ
                    <br />
                    VOS EXPRESSIONS DE BESOINS
                </router-link>

                <span class="mt-3 fs-5 text-white">
                    Pour toutes questions, contactez
                    <a href="mailto:contact@sayens.fr?subject=LAYDJ.tech 2 - DIJ'INNOV CONNECT" class="text-white">
                        <u>contact@sayens.fr</u>
                    </a>
                </span>
            </div>

            <div class="col-auto bg-white p-0 mb-0 mb-lg-5--5" style="width: 2px"></div>

            <div class="col-12 col-lg d-flex flex-column align-items-center mt-5 mt-lg-0 px-3 px-md-5 px-xl-5--5">
                <span class="home__programme__title-1">TABLES</span>
                <span class="home__programme__title-2">RONDES</span>
                <div class="separation bg-primary"></div>

                <span class="mb-4 fs-2">De 17H à 19H30</span>

                <div class="d-flex flex-column align-items-center text-center fs-4" style="line-height: 3rem">
                    <span class="mt-3">
                        Deux grandes thématiques sont à aborder avec différents intervenants afin d'encourager les
                        jeunes femmes à s'engager dans le processus <span class="text-nowrap">d'innovation :</span>
                    </span>
                    <span class="mb-3">
                        enseignement supérieur, recherche, transfert de technologie, création d'entreprise
                        innovante/startup.
                    </span>
                    <span class="my-3">
                        Participez à une ouverture sur un monde encore trop inaccessible aux femmes.
                    </span>
                </div>

                <div class="d-flex flex-column align-items-center">
                    <router-link to="/inscription" class="btn btn-lg my-3 px-4 py-2--5 fs-6 btn-primary">
                        JE M'INSCRIS
                    </router-link>
                    <button @click="openProgramPdf" class="btn btn-lg mt-3 px-4 py-2--5 fs-6 btn-orange">
                        VOIR LE PROGRAMME
                    </button>
                </div>
            </div>

            <div class="col-12 d-flex flex-column align-items-center text-center my-5 px-3 px-md-5 px-xl-5--5">
                <span class="home__programme__title-2 mt-0">COCKTAIL</span>
                <div class="separation bg-blue"></div>
                <span class="mt-2 mb-3 fs-2">De 19H30 à 21H</span>
                <span class="fs-4">Venez profiter d'un moment d'échange et de partage</span>
            </div>
        </div>
    </div>
</template>

<script>
import MyCountdown from "@/components/MyCountdown.vue";
import NavigationLinks from "@/components/NavigationLinks.vue";

export default {
    components: {
        MyCountdown,
        NavigationLinks,
    },
    data() {
        return {
            countdown_end: new Date("2024-03-27T15:00:00"),
        };
    },
    methods: {
        openProgramPdf() {
            window.open("/resources/PROGRAMME LADYj.Tech 2.pdf", "_blank");
        },
    },
};
</script>
