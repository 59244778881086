<template>
    <div class="acces">
        <h1 class="my-4 position-relative" style="z-index: 101">Accès & Contacts</h1>

        <div class="acces__infos-contact position-relative d-flex-center">
            <div class="container py-5">
                <div class="d-flex justify-content-md-around flex-wrap mt-2">
                    <h4 class="d-flex align-items-center text-600 m-4">
                        <i class="fa-solid fa-location-dot me-3 mb-0"></i>
                        <div class="d-flex flex-column arial-rounded">
                            <span class="fs-4">Campus métropolitain ESTP-ESEO</span>
                            <span class="fs-5 mt-1--5">11 Rue Sully, 21000 Dijon</span>
                        </div>
                    </h4>

                    <h4 class="d-flex align-items-center text-600 m-4">
                        <i class="fa-solid fa-address-book me-3 mb-0"></i>
                        <div class="d-flex flex-column arial-rounded">
                            <span class="fs-4">INNOV'events Dijon</span>
                            <a
                                href="mailto:dijon@innov-events.fr?subject=LAYDJ.tech 2 - Contacter les organisateurs"
                                class="fs-5 mt-1--5"
                            >
                                dijon@innov-events.fr
                            </a>
                        </div>
                    </h4>
                </div>
            </div>
        </div>
        <div class="acces__gmap mb-3">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d390.6287533077563!2d5.0668109340538185!3d47.317704004835605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f29f123b7cef0b%3A0xad7354c5a4e163a4!2sESTP%20-%20Campus%20de%20Dijon!5e0!3m2!1sfr!2sfr!4v1706787165520!5m2!1sfr!2sfr"
                width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>

        <div class="acces__inscription d-flex-center flex-column pb-5 px-0 px-sm-5--5">
            <img src="../assets/img/bg-btn-inscription.svg" class="acces__inscription__bg" alt="" />

            <span class="fs-4 my-3">
                Pour participer à cette seconde édition, merci de bien vouloir vous inscrire.
            </span>

            <router-link to="/inscription" class="acces__inscription__btn btn btn-primary my-4 px-4 py-2--5 fs-3">
                JE M'INSCRIS
            </router-link>
        </div>

        <img class="w-100 position-relative bg-white" style="z-index: 101" src="../assets/img/bandeau-logos.png" />
    </div>
</template>

<script>
export default {
    created() {},
    data() {
        return {};
    },
    props: {},
    methods: {},
};
</script>
