<template>
    <div class="opportunites-labo inscription position-relative d-flex-center">
        <img src="../assets/img/bg-form.svg" class="inscription__bg" alt="" />

        <img src="../assets/img/logo-metro.svg" class="inscription__logo-metro" alt="" />

        <div class="container-fluid opportunites-labo__form mx-auto d-flex-center flex-column my-4">
            <img src="../assets/img/logo-2.svg" class="inscription__logo mb-4" alt="" />

            <h2 class="w-100 bg-green-light text-white rounded mb-3 px-1--5 py-3">LES OPPORTUNITÉS "LABORATOIRES"</h2>

            <div class="bg-white p-3 shadow rounded w-100">
                <div class="accordion accordion-flush border rounded my-3 py-0--5 w-100" id="accordionThemes">
                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo2`">
                            <button
                                class="accordion-button collapsed d-flex flex-wrap align-items-baseline flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo2`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo2`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire Texte - Image - Langage </span>
                                <small>
                                    Gérer le « risque culturel » dans les coopérations internationales et les
                                    interactions numériques en équipes de projet.
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo2`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo2`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : Texte - Image - Langage%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoires :</span>
                                <p>Texte - Image - Langage</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Gérer le « risque culturel » dans les coopérations internationales et les
                                    interactions numériques en équipes de projet.
                                </p>

                                <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                <p>
                                    Tous secteurs, toute organisation mettant en œuvre des projets internationaux,
                                    impliquant ou non des partenaires externes
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span> projet international, interculturel,
                                    médiation identitaire, travail à distance, joint- venture, fusion-acquisition,
                                    communication numérique
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Les collaborations internationales, notamment lorsqu'elles s'appuient sur la
                                            communication numérique pour la gestion des interactions quotidiennes, font
                                            face à un risque d'ordre « culturel » et/ou identitaire.
                                        </p>
                                        <p>
                                            Les parties prenantes du projet (ex. projet interne à dimension
                                            internationale, développement international, joint venture…), doivent alors
                                            prendre en compte des « différences » perçues : de nationalité, de
                                            structure, de métier… qu'ils peuvent être tentés de nier, ou bien d'ériger
                                            en barrières insurmontables.
                                        </p>
                                        <p>
                                            Nier les possibles différences culturelles ou identitaires peut laisser des
                                            angles morts dans la collaboration, menant à des malentendus, ou bien peut
                                            aboutir à des sentiments de frustration. A l'opposé, surjouer la différence
                                            (qui peut être une stratégie chez les acteurs pour refuser l'Autre),
                                            occasionne des barrières symboliques autoréalisatrices.
                                        </p>
                                        <p>
                                            Les formations traditionnelles à l'internationalisation ou à l'expatriation
                                            ont tendance à accentuer l'impact potentiel de ces différences « culturelles
                                            », et donc à exacerber le risque de crispation identitaire. Or, il existe
                                            d'autres approches de l'interculturel qui abordent les contacts au sein
                                            d'équipes internationales de manière « positive ».
                                        </p>
                                        <p>
                                            Elles soulignent la multiplicité des cultures et des identités comme autant
                                            de repères communs et de ressources interpersonnelles pouvant servir
                                            l'équipe de projet, afin de négocier des pratiques et des normes partagées.
                                        </p>
                                        <p>
                                            En même temps, elles mettent en avant la possible instrumentalisation des
                                            identités qui peut faire échouer la dynamique collective, en instaurant des
                                            rivalités intergroupes, au sein de l'équipe. Ces approches récentes ne font
                                            pas l'objet de formations en entreprise.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Les collaborations internationales concentrent souvent beaucoup
                                            d'investissements et influencent l'image d'une organisation à l'étranger.
                                        </p>
                                        <p>
                                            Elles peuvent être des opportunités de croissance individuelle et
                                            collective, tout comme elles peuvent représenter un risque d'échecs coûteux
                                            sur les plans économique, symbolique, social.
                                        </p>
                                        <p>
                                            Afin de réduire ce risque, le projet de thèse a un double objectif
                                            scientifique et industriel.
                                        </p>
                                        <p>
                                            Il vise à accompagner des équipes lors de projets à dimension
                                            internationale, grâce à une médiation individuelle ou collective, pour
                                            éviter des crispations identitaires liées à une non prise en compte ou à un
                                            surinvestissement de la différence.
                                        </p>
                                        <p>
                                            Et à partir de ce travail, analysé et théorisé, il mettra au point des
                                            outils de formation et de médiation, afin de promouvoir les « bonnes
                                            pratiques » pour ce type de collaboration.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            La dimension numérique des relations entre les membres des équipes fera
                                            l'objet d'une attention toute particulière, partant de l'hypothèse d'un
                                            risque augmenté de malentendus lié à un appauvrissement des contacts
                                            interpersonnels.
                                        </p>
                                        <p>
                                            A travers l'analyse effectuée, la thèse fournira des préconisations sur la
                                            conduite de projets à distance, et sur des outils de communication qui
                                            peuvent réduire l'impact négatif de cette dimension sur les relations au
                                            sein d'une équipe internationale.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo12`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo12`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo12`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire Texte - Image - Langage </span>
                                <small>
                                    La réalité augmentée au service de l'accessibilité : pour des contenus à destination
                                    de tous les apprenants (titre de travail)
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo12`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo12`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : Texte - Image - Langage%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>Texte - Image - Langage</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    La réalité augmentée au service de l'accessibilité : pour des contenus à destination
                                    de tous les apprenants (titre de travail)
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Edition numérique, Réalité augmentée, Accessibilité
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    réalité augmentée, ressources pédagogiques, ingénierie pédagogique, modélisation,
                                    design, ergonomie de l'accessibilité
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            La loi pour une République numérique de 2016 impose une obligation
                                            d'accessibilité au secteur privé et aux services publics à destination des
                                            personnes sourdes, sourdaveugles, malentendantes et aphasiques. Il est donc
                                            crucial que le secteur de l'enseignement supérieur permette l'accessibilité
                                            de contenus numériques supports d'enseignement à ces catégories
                                            d'étudiant.e.s, appelé.e.s à maîtriser ces nouvelles interfaces tout en
                                            prenant en compte le coût élevé de l'acquisition de nouveaux devices
                                            immersifs et leur empreinte écologique.
                                            <br />
                                            L'offre est à ce jour quasi-inexistante.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Au niveau scientifique, le sujet proposé vise à développer une méthodologie
                                            transférable de l'accessibilité technologique des nouvelles interfaces en
                                            réalité augmentée 1)modélisation des besoins à partir de besoins en
                                            accessibilité spécifiques et identifiés, 2) définition et modélisation des
                                            process, 3) réalisation d'une ressource-prototype en RA pouvant être
                                            implémentée pour d'autres contenus, et 4) validation.
                                        </p>
                                        <p>
                                            Au niveau industriel l'enjeu est double : d'une part disposer d'une solution
                                            d'accessibilité applicable à des contenus pédagogiques variés et d'autre
                                            part, permettre sinon d'automatiser du moins de rationaliser la création de
                                            ces contenus à travers un process écologiquement soutenable.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <ul>
                                            <li>modélisation de process en RA adaptables et transférables</li>
                                            <li>
                                                rationalisation de la production pour son déploiement et sa
                                                diversification
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo1`">
                            <button
                                class="accordion-button collapsed d-flex flex-wrap align-items-baseline flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo1`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo1`"
                            >
                                <span class="mb-2 text-green-light">
                                    Laboratoire Texte - Image - Langage / Institut Carnot de Bourgogne
                                </span>
                                <small>
                                    Améliorer l'idiomaticité et la fluidité en traduction automatique multilingue : vers
                                    une post-édition semi-automatique à partir de corpus (titre de travail)
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo1`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo1`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : Texte - Image - Langage / Institut Carnot de Bourgogne%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoires :</span>
                                <p>Laboratoire Texte - Image - Langage / Institut Carnot de Bourgogne</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Améliorer l'idiomaticité et la fluidité en traduction automatique multilingue : vers
                                    une post-édition semi-automatique à partir de corpus (titre de travail)
                                </p>

                                <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                <ul>
                                    <li>Industries de la langue / services linguistiques</li>
                                    <li>Traduction automatique</li>
                                    <li>Numérique</li>
                                    <li>IA</li>
                                </ul>

                                <p>
                                    <span class="body-title">Mots-clefs :</span> traduction neuronale, modèles de
                                    langage, post-édition automatique, corpus, ingénierie linguistique
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Le contexte général est celui de l'arrivée massive, dans le secteur de la
                                            traduction, des solutions de traduction neuronale qui bouleversent le flux
                                            de traduction. Dans ce contexte, les opérations dites de post-édition
                                            deviennent cruciales et tendent à être aussi semi-automatisées.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Au niveau scientifique, le sujet proposé vise à développer une méthodologie
                                            transférable de 1) constitution, 2) segmentation, et 3) modélisation de
                                            corpus plurilingues comparables satisfaisant pouvant être implémentés pour
                                            alimenter un outil de post-édition
                                        </p>
                                        <p>
                                            Au niveau industriel l'enjeu est double : d'une part disposer d'une solution
                                            en temps réel et d'autre part permettre d'automatiser la création de
                                            contenus multilingues.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <span>
                                            <ul>
                                                <li>
                                                    Appariement conceptuel et modélisation de corpus comparables
                                                    (hautement) spécialisés dans x langues
                                                </li>
                                                <li>
                                                    Mode de segmentation des corpus sur la base de schémas/patterns
                                                    associant à la terminologie traditionnelle à l'environnement
                                                    morpho-syntaxique naturel dans la langue cible
                                                </li>
                                            </ul>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo4b04`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo4b04`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo4b04`"
                            >
                                <span class="mb-2 text-green-light">
                                    Laboratoire Texte - Image - Langage / Institut Carnot de Bourgogne
                                </span>
                                <small>
                                    Cybersécurité linguistique pour la détection des menaces potentielles sur les
                                    réseaux sociaux.
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo4b04`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo4b04`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : Texte - Image - Langage / Institut Carnot de Bourgogne%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>Texte - Image - Langage / Institut Carnot de Bourgogne</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Cybersécurité linguistique pour la détection des menaces potentielles sur les
                                    réseaux sociaux.
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Technologie de l'information, sécurité en ligne, analyse des données, cybersécurité,
                                    prévention de la criminalité sur les réseaux sociaux
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    Cybersécurité ; Sentiment Analysis ; Linguistique Forensique ; Opinion Mining ;
                                    Menaces potentielles ; Réseaux sociaux ; Analyse linguistique ; Sécurité en ligne ;
                                    Modèle prédictif ; Médias sociaux ; Prévention des activités criminelles ;
                                    Sociolinguistique ; Analyse des données ; Technologie de l'information ; Détection
                                    des risques ; Contexte culturel et linguistique.
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Cette recherche s'inscrit dans un paysage socio-numérique en constante
                                            évolution, où les médias sociaux dominent. Face à la prolifération rapide
                                            des interactions en ligne, il est crucial de développer des méthodes
                                            avancées d'analyse des sentiments et de linguistique forensique pour
                                            comprendre en profondeur l'opinion publique et détecter les menaces
                                            potentielles. L'influence significative des médias sociaux sur la formation
                                            des opinions et la diffusion d'informations souligne la nécessité d'une
                                            approche innovante pour décrypter les dynamiques sociales, identifier les
                                            risques criminels émergents et renforcer la sécurité numérique.
                                            L'intégration de la linguistique forensique offre une approche novatrice
                                            pour évaluer la crédibilité des informations en ligne, identifiant les
                                            intentions dissimulées et discernant les manipulations potentielles au sein
                                            des discours numériques.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs spécifiques :</span>
                                        <ul>
                                            <li>
                                                Analyser les données sociales pour comprendre les spécificités
                                                culturelles et linguistiques en ligne, contextualisant ainsi l'analyse
                                                des sentiments.
                                            </li>
                                            <li>
                                                Intégrer la linguistique forensique pour identifier précisément les
                                                menaces, intentions dissimulées et manipulations linguistiques dans les
                                                discours numériques.
                                            </li>
                                            <li>
                                                Développer un modèle prédictif avancé basé sur l'analyse textuelle pour
                                                anticiper les tendances émergentes et détecter les signaux faibles des
                                                activités criminelles.
                                            </li>
                                            <li>
                                                Évaluer rigoureusement la méthodologie en mettant l'accent sur la
                                                précision, le rappel et l'exactitude du modèle.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            Les principaux verrous technologiques à surmonter comprennent l'intégration
                                            efficace de la linguistique forensique pour développer des mécanismes
                                            d'intégration sophistiqués pour garantir une collaboration harmonieuse entre
                                            l'analyse des sentiments et la linguistique forensique. Cela nécessite une
                                            adaptation constante aux évolutions du langage en ligne, en mettant en œuvre
                                            des méthodes avancées pour identifier les traces linguistiques indiquant des
                                            menaces potentielles. Construire un modèle prédictif résilient qui peut
                                            anticiper les tendances émergentes avec précision. Cela implique le recours
                                            à des techniques d'apprentissage automatique de pointe ou à des approches
                                            basées sur des réseaux neuronaux, tout en garantissant une adaptabilité aux
                                            variations rapides du langage et des comportements en ligne. Assurer la
                                            confidentialité des données tout au long du processus, depuis la collecte
                                            jusqu'à l'analyse. Cela nécessite la mise en œuvre de protocoles de sécurité
                                            robustes pour protéger les informations sensibles, minimisant ainsi les
                                            risques liés à la manipulation ou à la divulgation non autorisée des
                                            données.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo3`">
                            <button
                                class="accordion-button collapsed d-flex flex-wrap align-items-baseline flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo3`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo3`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire CIMEOS </span>
                                <small>
                                    Exploitation info-communicationnelle des Réseaux socio-numérique : entre community
                                    management, influence en ligne et Intelligence Artificielle
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo3`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo3`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : CIMEOS%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>CIMEOS</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Exploitation info-communicationnelle des Réseaux socio-numérique : entre community
                                    management, influence en ligne et Intelligence Artificielle
                                </p>

                                <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                <p>tous secteurs (services de communication); secteur de la communication</p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span> community management, social media
                                    management, Intelligence Artificielle, Internet
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            massification de tous les usages d'Internet. Mise en place généralisée par
                                            les entreprises de stratégie de communication basée sur l'exploitation des
                                            réseaux sociaux (Facebook, Instagram, TikToK, X). Des enjeux d'efficacité,
                                            d'image institutionnelle, de communication de crise en ligne se posent.
                                            Quelles sont les obstacles d'usage et les risques de crise
                                            communicationnelle ?
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Compréhension des usages des RNS mis en place, accompagnement dans la mise
                                            en place d'une politique de community/socia- media Management,
                                            accompagnement reflexion prospective sur l'implication de l'IA (ex :
                                            chatbot) dans la mise en place de ces politiques.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            Sur-représentation de la communication stratégique marketing dans l'espace
                                            public numérique ; défiance des usagers envers l'intrusion publicitaire
                                            numérique.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo4`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo4`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo4`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire CIMEOS </span>
                                <small>
                                    Enjeux info-communicationnels du télétravail : entre bien-être au travail,
                                    inclusion-exclusion numérique, braconnage/bricolage et efficacité organisationnelle.
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo4`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo4`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : CIMEOS%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>CIMEOS</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Enjeux info-communicationnels du télétravail : entre bien-être au travail,
                                    inclusion-exclusion numérique, braconnage/bricolage et efficacité organisationnelle.
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>tous
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span> télétravail, usage des TIC,
                                    inclusion-exclusion numérique, intranet, communication numérique, communication
                                    managériale
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Dans la période covidienne, le travail à distance s'est généralisé. On loue
                                            la productivité à distance, mais on s'interroge sur l'impact en termes de
                                            créativité, d'innovation et d'intelligence collective. Aujourd'hui, les
                                            salariés revendiquent le maintien d'une ou deux journées de télétravail par
                                            semaine, mais le management cherche à limiter cette pratique, voire à
                                            relocaliser toutes les activités professionnelles entre les murs de
                                            l'organisation, en présentiel.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Comprendre les freins et les leviers d'usages du télétravail dans
                                            l'organisation. Envisager les représentations du management et des salariés
                                            par rapport au travail à distance. Accompagner l'organisation au déploiement
                                            du télétravail, en tenant compte des contraintes organisationnelles, des
                                            contraintes d'usages, des contraintes technologiques, des contraintes
                                            juridiques et du climat social.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            Sur le plan méthodologique : observation des pratiques de télétravail in
                                            situ. Dépasser le solutionnisme technologique du management. Dépasser les
                                            contraintes d'accès à la parole salariée dans un climat social qui pourrait
                                            être dégradé dans l'organisation.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo5`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo5`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo5`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire CIMEOS </span>
                                <small>
                                    L'inclusion numérique en organisation : comprendre la culture numérique des salariés
                                    pour envisager l'usage efficace des technologies de communication numérique, des
                                    progiciels, aux applications collaboratives et coopératives internes (Réseaux
                                    Sociaux Numériques, Intranets collaboratifs, progiciels)
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo5`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo5`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : CIMEOS%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>CIMEOS</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    L'inclusion numérique en organisation : comprendre la culture numérique des salariés
                                    pour envisager l'usage efficace des technologies de communication numérique, des
                                    progiciels, aux applications collaboratives et coopératives internes (Réseaux
                                    Sociaux Numériques, Intranets collaboratifs, progiciels)
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>tous
                                    secteurs
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span> Intranet collaboratif, Réseaux sociaux
                                    d'Entreprises, Culture numérique, Littéracie numérique, Fracture numérique,
                                    inclusion-exclusion numérique, Communication managériale.
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            depuis la crise COVID , on observe une massification de tous les usages
                                            collaboratifs des TIC en interne dans les organisations. Mais il y a une
                                            présence maintenue d'une forme de fracture numérique ; de rejet des outils
                                            numériques par de nombreux salariés - alors que la connexion aux outils
                                            internet grand public ne semble plus un problème.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Comprendre les non-usages, ou les mésusages - détournements d'usage- des
                                            outils numériques proposés par l'entreprise. Évaluer les pratiques,
                                            compétences, connaissances et représentations des salariés face aux outils
                                            numériques internes à l'organisation. Accompagner l'entreprise dans la
                                            construction et la mise en place d'une politique d'inclusion numérique
                                            efficace.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            Comprendre la nature du rejet ou non-usage des outils numériques. Dépasser
                                            ou intégrer la surcharge cognitive des salariés, la sursollicitassions
                                            numérique et l'accélération de l'activité professionnelle. Accepter de faire
                                            un pas de côté face à une approche managériale souvent marquée par le
                                            solutionniste technologique.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo6`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo6`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo6`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire ImViA </span>
                                <small> Contrôle de la déviation d'aspect non uniforme par l'image </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo6`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo6`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : ImViA%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>ImViA</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>contrôle de la déviation d'aspect non uniforme par l'image</p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    industrie et contrôle qualité
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span> texture, imagerie, perception, contrôle
                                    qualité
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>contrôle qualité en industrie</p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            pouvoir mesurer une écart d'aspect visuel au cours du temps sur un chaîne de
                                            construction
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            mesure de distance entre aspect non-uniforme en lien avec la perception
                                            humaine
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo7`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo7`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo7`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire ImViA </span>
                                <small>
                                    Optimisation de la gestion agronomique des vignobles par robotique intelligente
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo7`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo7`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : ImViA%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>ImViA</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>Optimisation de la gestion agronomique des vignobles par robotique intelligente</p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    agriculture, agronomie, viticulture
                                </p>

                                <p><span class="body-title">Mots-clefs :</span> vision par ordinateur, robotique, IA</p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            L'agriculture de précision représente une évolution majeure dans le secteur
                                            agricole, offrant des possibilités inédites pour optimiser la gestion des
                                            cultures et minimiser l'impact environnemental. Dans le contexte spécifique
                                            de la viticulture, la robotique agricole autonome promet de révolutionner
                                            les méthodes traditionnelles de suivi et de soin des vignobles. Le
                                            déploiement de robots capables de naviguer de manière autonome dans les
                                            champs de vigne, de cartographier précisément leur environnement et de
                                            réaliser une segmentation sémantique pour la détection fine des symptômes de
                                            maladies, l'état de croissance des feuilles et des bois, ainsi que le
                                            rendement potentiel, pourrait permettre une gestion agronomique plus
                                            précise, réactive, et respectueuse de l'environnement.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <ul>
                                            <li>
                                                <b>Développement d'algorithmes de navigation autonome :</b> Mettre au
                                                point des algorithmes avancés permettant une navigation précise et sûre
                                                des robots dans les environnements complexes et dynamiques des champs de
                                                vigne.
                                            </li>

                                            <li>
                                                <b
                                                    >Amélioration de la cartographie de précision et de la segmentation
                                                    sémantique :</b
                                                >
                                                Créer des méthodes innovantes pour la cartographie détaillée des champs
                                                de vigne et la segmentation sémantique des images capturées, afin de
                                                détecter avec précision les symptômes de maladies, la croissance des
                                                feuilles et des bois, et estimer le rendement.
                                            </li>

                                            <li>
                                                <b>
                                                    Intégration des technologies pour une gestion agronomique optimisée
                                                    :
                                                </b>
                                                Concevoir un système intégré capable d'utiliser les données recueillies
                                                par les robots pour informer et optimiser les décisions agronomiques,
                                                réduire l'utilisation des ressources et améliorer la santé et le
                                                rendement des vignes.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <ul>
                                            <li>
                                                <b>Robustesse de la navigation autonome :</b> Surmonter les défis liés à
                                                la navigation autonome dans des environnements agricoles variés et sous
                                                des conditions météorologiques changeantes, y compris la gestion des
                                                obstacles inattendus et la variation des terrains.
                                            </li>

                                            <li>
                                                <b>Précision de la cartographie et de la segmentation sémantique :</b>
                                                Développer des techniques de traitement d'images et d'apprentissage
                                                profond capables de gérer la grande variabilité des scènes naturelles,
                                                pour une identification précise et une quantification des indicateurs de
                                                santé des plantes et du rendement.
                                            </li>

                                            <li>
                                                <b>Intégration et traitement des données en temps réel :</b> Mettre au
                                                point des solutions pour l'acquisition, le traitement, et l'analyse en
                                                temps réel des données volumineuses générées par les robots, afin de
                                                permettre une prise de décision agronomique rapide et fondée sur des
                                                données.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                                <p>
                                    Ce sujet de thèse vise donc à adresser ces défis majeurs à travers le développement
                                    et l'intégration de technologies de pointe en robotique, vision par ordinateur et
                                    intelligence artificielle, en vue d'améliorer significativement la gestion et la
                                    productivité des vignobles, tout en réduisant leur empreinte écologique.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo8`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo8`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo8`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire ImViA </span>
                                <small>
                                    Suivi temps réel du vol de petits objets par des mini-drones - application à la
                                    télésurveillance dynamique et/ou à l'étude du vol de petites espèces animales
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo8`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo8`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : ImViA%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>ImViA</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Suivi temps réel du vol de petits objets par des mini-drones - application à la
                                    télésurveillance dynamique et/ou à l'étude du vol de petites espèces animales
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    télésurveillance dynamique, industrie, environnement
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    mini-drones, petites cibles en mouvement, contrôle, surveillance dynamique, suivi
                                    rapide d'objets dans l'espace, petites espèces animales volantes, vision, signal
                                    (son), IA, flotte de systèmes embarqués volants connectés.
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            la thématique proposée porte sur le suivi et l'étude des mouvements de
                                            cibles rapides dans l'espace depuis un ou plusieurs mini-drones (flotte de
                                            mini- drones connectés). Une application envisagée, en guise de
                                            démonstrateur, est le suivi d'espèces volantes : frelon asiatique à des fins
                                            de repérage des nids, suivi de petits oiseaux pour l'étude de leur vol.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            les objectifs scientifiques portent sur la mise au point d'algorithmes temps
                                            réel de reconnaissance d'objets en déplacement très rapide et de leur suivi.
                                            L'étude scientifique pourra être étendue à l'identification et à la
                                            localisation de sources sonores mobiles et fixes. Ces objectifs
                                            scientifiques porteront également sur le développement d'une stratégie de
                                            déploiement de mini- drones connectés pour assurer la détection et le suivi
                                            des cibles en mouvement. Les objectifs industriels concernent par exemple la
                                            télésurveillance dynamique de sites, le suivi d'objets mobiles dans
                                            l'espace, la reconnaissance, l'étude et la surveillance de la faune dans les
                                            milieux naturels.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            algorithmes rapides et adaptatifs de détection et de reconnaissance de
                                            petites cibles (par rapport au champ de la caméra) dans un environnement
                                            inconnu a priori ; suivi des cibles rapides (déplacement inter-image de
                                            plusieurs pixels) par une flotte de mini-drones ; identification et suivi de
                                            sources sonores fixes ou en mouvements dans un environnement réel complexe ;
                                            synchronisation d'une flotte de plusieurs mini-drones avec acquisition ou
                                            perte de la ou des cibles.
                                        </p>
                                    </li>
                                </ul>

                                <div>
                                    <span class="body-title">Références :</span>
                                    <p>
                                        Dongfeng Shi, Kaixin Yin, Jian Huang, Kee Yuan, Wenyue Zhu, Chenbo Xie, Dong
                                        Liu, Yingjian Wang, Fast tracking of moving objects using single-pixel imaging,
                                        Optics Communications, Volume 440, 2019, Pages 155-162,
                                    </p>
                                    <p>
                                        Dillon Reis and Jordan Kupec and Jacqueline Hong and Ahmad Daoudi, Real-Time
                                        Flying Object Detection with YOLOv8, 2023, arXiv 2305.09972}
                                    </p>
                                    <p>
                                        H. Jia, B. Li, Q. Fei and Q. Wang, &quot;Fast-Moving Target Tracking Based on
                                        KCF with Motion Prediction,&quot; 2023 42nd Chinese Control Conference (CCC),
                                        Tianjin, China, 2023, pp. 7837- 7842
                                    </p>
                                    <p>
                                        H. Yin, Y. Chai, S. X. Yang and X. Yang, &quot;Fast-moving target tracking based
                                        on mean shift and frame-difference methods,&quot; in Journal of Systems
                                        Engineering and Electronics, vol. 22, no. 4, pp. 587-592, Aug. 2011, doi:
                                        10.3969/j.issn.1004-4132.2011.04.006
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo9`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo9`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo9`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire ImViA </span>
                                <small> Protection des données numériques à base de l'IA embarquée. </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo9`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo9`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : ImViA%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>ImViA</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>Protection des données numériques à base de l'IA embarquée.</p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Systèmes embarqués sécurisés et informatique industrielle.
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    IA, FPGA, MPSoC, Cryptographie, Sécurité de l'information, IoT
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Avec le progrès de l'IA ces dernières années, plusieurs domaines ont sautés
                                            un grand pas vers l'avenir, tel que la découverte de nouveaux matériaux, la
                                            détection d'objets en temps réel, les systèmes d'aide à la conduite dans les
                                            voitures semi-autonomes, la génération de données multimédias synthétiques,
                                            etc. Mais, l'IA est aussi utilisée pour des raisons malveillantes. Par
                                            exemple, les hackers essaient d'accéder à des données personnelles sensibles
                                            qui circulent de plus en plus sur les différents types de réseaux qu'on
                                            utilise de nos jours. Pour remédier à ce problème il est primordial de
                                            trouver de nouvelles solutions de sécurité permettant de résister à ces
                                            attaques. C'est donc l'objet de cette thématique de recherche.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            La protection en temps réel des données sensibles contre les nouvelles
                                            générations d'attaques basées sur l'IA.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            Combinaison des atouts de la cryptographie standard avec la cryptographie
                                            intelligente pour assurer une meilleur protection des données dans un monde
                                            numérique (IoT, Cloud, réseaux sans fils…).
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo10`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo10`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo10`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire ImViA </span>
                                <small> Analyse de scènes par imagerie non conventionnelle et multimodale </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo10`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo10`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : ImViA%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>ImViA</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>Analyse de scènes par imagerie non conventionnelle et multimodale</p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Industrie 4.0, Santé/Biologie, Patrimoine, Agronomie
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    imagerie multi et hyper-spectrale, image de fluorescence, imagerie polarimétrique
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Alors que les systèmes d'imagerie deviennent incontournables dans de
                                            nombreux domaines afin de planifier, suivre, détecter, caractériser ou
                                            encore reconnaitre, la transformation numérique ne fait que renforcer
                                            l'utilisation des capteurs de vision. L'imagerie non conventionnelle apporte
                                            la captation de signaux inaccessibles par le biais des caméras classiques
                                            (niveau de gris ou couleur). Cette nouvelle source d'informations devient
                                            fondamentale pour acquérir les données nécessaires à la prise de décision ou
                                            pour une aide au diagnostic par un système informatique. On peut citer les
                                            modalités suivantes : imagerie multi et hyper-spectrale, image de
                                            fluorescence et imagerie polarimétrique.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Les objectifs sont bien évidemment applicatif-dépendants. Dans de nombreux
                                            cas, il est nécessaire de coupler différentes technologies pour acquérir des
                                            données complémentaires qui, après fusion, permettront d'aboutir à une
                                            décision rationnelle. Sur le plan scientifique, les objectifs concernent le
                                            choix des modalités d'imagerie ainsi que les algorithmes à mettre en œuvre
                                            afin de combiner les signaux obtenus pour aboutir à une solution à même de
                                            répondre aux contraintes applicatives. Sur le plan technologique, les
                                            objectifs consistent à intégrer ces nouveaux systèmes dans un processus de
                                            transformation numérique.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            Les verrous technologiques majeurs à lever sont liés à la prise en compte du
                                            processus industriel, sociétal et environnemental afin de garantir une
                                            transition vers ces technologies d'imagerie non conventionnelle,
                                            respectueuse des contraintes métier imposées par le partenaire.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo11`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo11`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo11`"
                            >
                                <span class="mb-2 text-green-light">
                                    Laboratoire PAM (UMR uB-Institut Agro Dijon-INRAE) Equipe AFIM
                                </span>
                                <small> Biocontrôle de la levure d'altération Brettanomyces bruxellensis </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo11`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo11`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : PAM (UMR uB-Institut Agro Dijon-INRAE) Equipe AFIM%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>PAM (UMR uB-Institut Agro Dijon-INRAE) Equipe AFIM</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>Biocontrôle de la levure d'altération Brettanomyces bruxellensis</p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Filière vinicole
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    antagonisme / vin / défauts organoleptiques /contrôle
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Dans un contexte de réduction des intrants (dioxyde de soufre) et de
                                            changement climatique, qui modifie la matrice vin et particulièrement le pH
                                            avec une élévation de celui-ci qui va à l'encontre de l'efficacité du
                                            dioxyde de soufre, trouver de nouveaux moyens de contrôle de Brettanomyces
                                            s'avère incontournable. La voie du biocontrôle répondrait aux attentes des
                                            professionnels et à la demande sociétale.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques :</span>
                                        <p>Les objectifs scientifiques de ce projet sont :</p>
                                        <ul>
                                            <li>
                                                la recherche de souches de bactéries lactiques (Oenococcus oeni ou
                                                autres bactéries lactiques …) pouvant avoir un effet antagoniste contre
                                                la levure d'altération (différentes souches testées ayant des capacités
                                                différentes à tolérer le SO 2 )
                                            </li>
                                            <li>
                                                la caractérisation des potentialités fermentaires des souches
                                                sélectionnées d'intérêt
                                            </li>
                                            <li>la définition des conditions favorisant l'antagonisme</li>
                                            <li>la compréhension des mécanismes impliqués.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Objectifs industriels / Verrous technologiques à lever :</span>
                                        <p>
                                            Screening de souches d'intérêt antagoniste contre différentes souches de
                                            Brettanomyces bruxellensis
                                            <br />
                                            Sélections de souches antagonistes ayant des potentialités œnologiques
                                            d'intérêt comme par ex :
                                        </p>
                                        <ul>
                                            <li>efficacité à performer la fermentation malolactique</li>
                                            <li>
                                                phénol-négative : pas de production de précurseurs susceptibles d'être
                                                utilisés par les levures Brettanomyces aboutissant à la la production de
                                                phénol volatiles responsables de l'altération des vins.
                                            </li>
                                        </ul>
                                        <p>
                                            Elaboration de protocoles de mise en œuvre selon la situation de
                                            contamination
                                        </p>
                                    </li>
                                </ul>

                                <div>
                                    <span class="body-title">Relation partenariale :</span>
                                    <p>
                                        La relation partenariale envisagée est une collaboration de recherche type thèse
                                        CIFRE. La propriété des résultats scientifiques générés par le projet (souches,
                                        compréhension des mécanismes d'antagonisme) sera partagée entre l'établissement
                                        de recherche et l'entreprise partenaire qui sera identifiée comme prioritaire
                                        pour leur exploitation commerciale en cas de succès du projet.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo13`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo13`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo13`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire CREGO - Université de Bourgogne </span>
                                <small>
                                    CSRD et changement de paradigme : la stratégie RSE comme pilier de la gouvernance
                                    des entreprises durables (titre de travail).
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo13`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo13`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : CREGO - Université de Bourgogne.%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>CREGO - Université de Bourgogne</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    CSRD et changement de paradigme : la stratégie RSE comme pilier de la gouvernance
                                    des entreprises durables (titre de travail).
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Gestion des déchets, recyclage, emballage, pharmaceutique, électronique, papier,
                                    carton, alimentaire, construction, automobile, etc.
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    RSE, durabilité, performance, résilience, gouvernance, stratégie, développement
                                    durable, ESG, RSO, CSRD, normes ESRS, Agenda 2030, reporting extra-financier
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Le contexte général est celui de l'application de la nouvelle directive
                                            européenne CSRD, et des opportunités d'amélioration et de transformation de
                                            la stratégie RSE des entreprises qu'elle offre.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Au niveau scientifique, le sujet proposé vise à analyser et documenter le
                                            changement paradigmatique que la directive CSRD induit en termes de
                                            stratégie RSE pour les entreprises concernées, mettant leur durabilité au
                                            même plan que leur performance. Au niveau industriel l'enjeu est de
                                            permettre à une entreprise du secteur d'être accompagnée dans
                                            l'actualisation ou la redéfinition de sa stratégie RSE, tout en permettant
                                            de la mesurer et de mesurer ses impacts sur sa gouvernance et ses activités.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <ul>
                                            <li>
                                                Trouver le moyen de mesurer efficacement la RSE, au-delà du score ESG
                                                (Environnement, Social et Gouvernance) qui mesure uniquement l'intensité
                                                de communication extra-financière (ICE) c'est-à-dire les performances
                                                non financières d'une entreprise dans les catégories environnementales,
                                                sociales et de gouvernance.
                                            </li>
                                            <li>
                                                Identifier les impacts de la RSE sur la gouvernance et les activités de
                                                l'entreprise (et notamment sur la capacité de résilience).
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo14`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo14`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo14`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire LIB </span>
                                <small>
                                    Représentation et extraction de connaissances basées sur les ontologies et
                                    l'apprentissage pour l'aide à la décision
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo14`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo14`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : Représentation et extraction de connaissances basées sur les ontologies et l'apprentissage pour l'aide à la décision%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>LIB</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Représentation et extraction de connaissances basées sur les ontologies et
                                    l'apprentissage pour l'aide à la décision
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Finance, Santé, Environnement
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    Extraction de connaissances, ontologies, apprentissage automatique, systèmes de
                                    recommandation, aide à la décision
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Face à la croissance exponentielle de la quantité d'informations dans tous
                                            les domaines, il devient essentiel de filtrer et d'extraire les informations
                                            pour les utilisateurs en fonction de leurs besoins et préférences. Les
                                            ontologies et l'apprentissage automatique offrent des perspectives
                                            prometteuses pour relever ces défis. Les ontologies structurent les
                                            connaissances de manière précise et interopérable, permettant une
                                            compréhension approfondie des domaines concernés. L'apprentissage
                                            automatique, quant à lui, découvre des motifs et des tendances dans de
                                            vastes ensembles de données, offrant la capacité de prédire et d'agir de
                                            manière éclairée.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Ce projet vise à développer des méthodes avancées pour la représentation et
                                            l'extraction de connaissances, en se concentrant sur l'utilisation des
                                            ontologies et de l'apprentissage automatique. L'objectif est de fournir des
                                            systèmes d'aide à la décision plus précis et plus adaptatifs, capables de
                                            répondre aux besoins spécifiques de chaque secteur.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            Les principaux défis incluent l'amélioration de l'interopérabilité des
                                            données entre différents domaines, la gestion de la complexité et de la
                                            grande variété des données, ainsi que le développement d'algorithmes
                                            d'apprentissage capables de travailler efficacement avec des ontologies
                                            complexes.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo9e01`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo9e01`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo9e01`"
                            >
                                <span class="mb-2 text-green-light">
                                    Laboratoire ICB, CNRS/Université de Bourgogne
                                </span>
                                <small>
                                    Élaboration de nanoparticules à façon : nues ou fonctionnalisées en surface
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo9e01`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo9e01`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : Laboratoire ICB, CNRS/Université de Bourgogne%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>Laboratoire ICB, CNRS/Université de Bourgogne</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>Élaboration de nanoparticules à façon : nues ou fonctionnalisées en surface</p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Pharmaceutique, peintures, composites, colorants, cosmétique, énergie,
                                    chimie/catalyse, ciments, nanomédecine…
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    Nanoparticules, nanocomposites, nanohybrides, suspension, colloïde
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            le transfert industriel des nanoparticules développées par le secteur
                                            académique se voit généralement freiné voir stoppé par la difficulté de
                                            produire à grande échelle et de manière reproductible, pour répondre aux
                                            exigences industrielles
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Développer des procédés de synthèses entre le laboratoire académique et les
                                            partenaires industriels permettant de produire et purifier des suspensions
                                            de nanoparticules pour diverses applications technologiques en se
                                            concentrant sur la reproductibilité et le passage à grande échelle.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            le laboratoire ICB possède une expérience et un dispositif permettant une
                                            production en flux continu de nanoparticules inorganiques. A l'aide de ce
                                            procédé, il est également possible de modifier la surface des nanoparticules
                                            pour améliorer leurs stabilités chimique et/ou colloïdale ainsi que leur
                                            biocompatibilité. Il reste, à l'aide de ce dispositif d'essayer de se
                                            rapprocher de production de type GMP ou GLP pour accélérer le transfert de
                                            technologie vers le monde industriel.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo27da`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo27da`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo27da`"
                            >
                                <span class="mb-2 text-green-light">
                                    Laboratoire ICB, CNRS/Université de Bourgogne
                                </span>
                                <small> Évaluation et accompagnement dans la gestion du risque nano </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo27da`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo27da`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : Laboratoire ICB, CNRS/Université de Bourgogne%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>Laboratoire ICB, CNRS/Université de Bourgogne</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>Évaluation et accompagnement dans la gestion du risque nano</p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Pharmaceutique, peintures, composites, colorants, cosmétique, énergie,
                                    chimie/catalyse, ciments, nanomédecine, usinage mécanique…
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    (Nano)Toxicologie caractérisations, quantification, nanoparticules, nanocomposites,
                                    poudres
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Les risques associés aux nanotechnologies et aux nouveaux produits qu'elles
                                            génèrent sont encore mal connus. Peu de données sont disponibles sur les
                                            interactions nanoparticules / systèmes vivants ; il est ainsi difficile
                                            d'estimer le risque potentiel qu'ils représentent pour la santé humaine. Il
                                            parait alors nécessaire d'évaluer les dangers, à partir d'une meilleure
                                            connaissance de leurs effets biologiques. Par ailleurs, la règlementation
                                            autour des nanoparticules s'est durcie. Ainsi, les entreprises utilisant ces
                                            dernières sont soumises à déclaration annuelle depuis 2014 (loi Grenelle) et
                                            certains produits doivent être exempts de nanoparticules (Loi Egalim 2018).
                                            Par ailleurs, il existe peu d'outils permettant de détecter des
                                            nanoparticules dans une matrice et la toxicologie induite par l'état
                                            nanoparticulaire de certains composés utilisés par le secteur industriel.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Prouver l'absence ou quantifier la présence de nanoparticules dans une
                                            atmosphère ou une matrice (solide ou liquide). Mettre les procédés en
                                            conformité par des approches Safer-by-Design, sur toute la chaîne de
                                            développement du produit. Etudier la toxicité ou l'innocuité des
                                            nanoparticules utilisées.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            Détecter/quantifier des particules fines dans une atmosphère, une
                                            suspension/solution, une matrice. Gérer le risque nano au sein de
                                            l'entreprise et au regard de la législation européenne, former les
                                            personnels à ces risques. Réaliser et développer, en collaboration avec des
                                            laboratoires de biologie de l'uB, des analyses toxicologiques (CTM INSERM,
                                            CSGA…) adaptées aux nanoparticules.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo6a23`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo6a23`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo6a23`"
                            >
                                <span class="mb-2 text-green-light">
                                    Laboratoire ICB, CNRS/Université de Bourgogne
                                </span>
                                <small>
                                    Développement de nouveaux capteurs à base de nanoparticules d'oxydes métalliques ou
                                    de métaux
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo6a23`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo6a23`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : Laboratoire ICB, CNRS/Université de Bourgogne%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>Laboratoire ICB, CNRS/Université de Bourgogne</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Développement de nouveaux capteurs à base de nanoparticules d'oxydes métalliques ou
                                    de métaux
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Industrie 4.0, Santé, agroalimentaire, environnement…
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    Détecteur, capteur, matériau sensible, détection, sensibilité, sélectivité,
                                    nanoparticules, fonctionnalisation.
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Les limites de détection en temps réel et la sélectivité des détecteurs
                                            portatifs ou microdispositifs low-cost tels que des capteurs sont parfois
                                            insuffisantes. L'utilisation de matériaux sensibles à base de nanoparticules
                                            fonctionnalisées ou non vise à dépasser ces limites rendant détecteurs et
                                            capteurs plus sensibles et sélectifs. L'expérience pluridisciplinaire de
                                            l'ICB (deux départements), riche de plusieurs brevets (synthèse de
                                            nanomatériaux, détection de pesticides…) et contrats industriels (dont
                                            l'ESA) vise à proposer et développer détecteurs et capteurs (version low
                                            cost) pour la détection de polluants en milieu liquide ou gazeux ou de
                                            biomolécules pour le secteur de la santé.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Utiliser les propriétés uniques des nanoparticules pour développer ou
                                            améliorer des capteurs en augmentant leur seuil de détection, leur
                                            sélectivité, tout en les miniaturisant.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            Détecter/quantifier des analytes à l'aide de nanoparticules supportées ou en
                                            suspension. Designer à façon, les matériaux sensibles (cœur/coquille,
                                            composites, surfaces fonctionnalisées…) et géométrie de capteurs/ détecteurs
                                            selon les conditions environnementales.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo6d74`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo6d74`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo6d74`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire CAPS </span>
                                <small>
                                    Résonnance motrice et restauration des fonctions sensorimotrices par observation de
                                    l'action après lésion et maladie neurodégénératives
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo6d74`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo6d74`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : CAPS.%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>CAPS</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Résonnance motrice et restauration des fonctions sensorimotrices par observation de
                                    l'action après lésion et maladie neurodégénératives
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Thérapie Digitale- Santé numérique- IA et médecine personnalisée
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    Neurones miroirs- Rééducation supervisée- Dispositif Médical Numérique
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            En France, le nombre de personnes victimes d'AVC est proche d'un million et
                                            environ 150000 nouveaux cas sont recensés chaque année. Dans le monde, on
                                            évalue à 15 millions le nombre de nouveaux cas chaque année et à 70 millions
                                            le nombre de nouveaux cas prévus en 2030. L'AVC touche toutes les tranches
                                            d'âge et la moyenne d'âge des patients atteints est de 73 ans. Il y a 10
                                            ans, la prise en charge de l'AVC générait déjà 3,5 milliards d'euros de
                                            dépenses à l'Assurance maladie, soit 2,5 % de ses remboursements totaux.
                                            Mais les dépenses totales financées par la collectivité sont trois fois plus
                                            élevées, intégrant les dépenses post-AVC dont les dépenses médico-sociales
                                            liées au handicap. Malgré la prévalence de ce trouble, les soins réalisés
                                            chaque année manquent d'efficacité (cf. pour exemple les solutions à base de
                                            réalité virtuelle) et de preuves cliniques. Les traitements conventionnels
                                            (rééducation physique, réalité virtuelle, exosquelette, stimulation
                                            cognitive en serious game, électrostimulation musculaire et corticale, etc.)
                                            sont limités car ils :
                                        </p>
                                        <ul>
                                            <li>sont contraignants, fatigants et parfois douloureux,</li>
                                            <li>
                                                nécessitent l'intervention d'un soignant, dans un contexte de pénurie de
                                                praticiens,
                                            </li>
                                            <li>restent coûteux pour les systèmes de santé,</li>
                                            <li>
                                                sont inapplicables lorsque le patient est encore paralysé ou immobilisé,
                                            </li>
                                            <li>
                                                ne mobilisent pas suffisamment les réserves cérébrales pour maximiser la
                                                récupération fonctionnelle,
                                            </li>
                                            <li>
                                                ne s'appuient pas sur une collecte de données suffisantes pour prédire
                                                la dynamique de récupération via l'IA.
                                            </li>
                                        </ul>
                                        <p></p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            Développer un Dispositif Médical Numérique (DMN) visant à accélérer la
                                            rééducation motrice des membres supérieurs de patients en post-AVC grâce à
                                            une administration précoce. Il s'agit de programmes de rééducation par
                                            observation d'actions assistés par intelligence artificielle. De nombreuses
                                            études montrent que l'observation d'actions (e.g. vidéo d'un individu en
                                            bonne santé réalisant une tâche manuelle) active des zones du cerveau
                                            similaire à celles activées lors de l'exécution de la même action, ce qui
                                            favorise la plasticité cérébrale et donc la récupération des mouvements
                                            après un accident vasculaire cérébral. L'ajout d'intelligence artificielle
                                            permettra de sélectionner les vidéos et la fréquence d'entrainement la plus
                                            adaptée à chaque individu. Le DMN proposé ici, s'inscrit dans un parcours de
                                            soins post-AVC et peut démarrer dès la fin de la phase aiguë et se
                                            poursuivre pendant la phase chronique. Dans ce cadre, seules 10 à 20% des
                                            séances de rééducation sont réalisées chez un praticien, la majorité se
                                            déroulent à domicile en autonomie.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <ul>
                                            <li>Elaboration de profils thérapeutiques personnalisés,</li>
                                            <li>
                                                Déploiement à large échelle de manière automatisée et personnalisée,
                                            </li>
                                            <li>
                                                Collecte de biomarqueurs alimentant l'IA pour superviser la rééducation.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo6d54`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo6d54`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo6d54`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire CIAD </span>
                                <small> «Conception d'un SNN intuitif » </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo6d54`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo6d54`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : CIAD.%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>CIAD</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>«Conception d'un SNN intuitif »</p>
                                <p>
                                    NeuroSPIKE est un sujet porté par l'université de Bourgogne pour le « développement
                                    de réseaux de neurones de 3ème génération intuitifs ».
                                    <br />
                                    L'innovation porte sur la capacité d'un réseau à détecter son besoin de générer de
                                    nouvelles connexions pour s'optimiser.
                                    <br />
                                    Ce genre de technologie peut permettre par exemple l'augmentation de la résolution
                                    des EEG classiques 21 canaux, comme s'ils étaient capturés par un EEG 64 canaux.
                                    Nous disposons avec le CHU-CIC de telles données pour expérimenter le développement
                                    d'un tel outil.
                                    <br />
                                    Il peut également, dans le cas d'un patient atteint d'un AVC, permettre d'aider sa
                                    rééducation par la compréhension de sa reconstruction cérébrale.
                                    <br />
                                    De manière générale les SNN sont applicables sur toutes données séquentielles, et
                                    l'intuition artificielle permettrait d'accélérer et optimiser leur construction.
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Médical - tout secteur utilisant des données séquentielles (timeseries)
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    SNN, Intuition artificielle, EEG
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            L'objectif est de développer une nouvelle forme de SNN (Spiking Neural
                                            Network) qui soit intuitif. Ces réseaux trouveraient des applications dans
                                            énormément de domaines pour tout ce qui touche à la détection de relations
                                            manquantes (par oubli, par erreur, par méconnaissance…).
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>brevets</p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>Intégration de l'intuition artificielle dans un SNN</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglaboae99`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#laboae99`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselaboae99`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire CIAD </span>
                                <small>
                                    Modélisation et Gestion des Contraintes dans l'Industrie 4.0 : Approche Intégrée
                                    d'Intelligence Artificielle et de Génie Logiciel
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`laboae99`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglaboae99`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : CIAD.%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>CIAD</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Modélisation et Gestion des Contraintes dans l'Industrie 4.0 : Approche Intégrée
                                    d'Intelligence Artificielle et de Génie Logiciel
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Industrie 4.0 / Toutes les filières
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    Industrie 4.0, Intelligence Artificielle, Génie Logiciel, Gestion des connaissances,
                                    Modélisation des contraintes
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            L'avènement de l'Industrie 4.0 a radicalement transformé les environnements
                                            de production en introduisant des systèmes interconnectés, autonomes et
                                            intelligents. Cette révolution industrielle a entraîné une multitude de
                                            défis, notamment en ce qui concerne la gestion efficace des connaissances
                                            métiers et des contraintes. Ces contraintes, qu'elles soient liées au
                                            domaine, aux délais de production, à la qualité des produits ou tout
                                            simplement au contexte d'utilisation et aux profils d'usage, sont devenues
                                            plus complexes et dynamiques. La maîtrise de ces contraintes dès les
                                            premières phases du cycle de production est donc indispensable pour répondre
                                            aux besoins complexes de l'industrie moderne.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>
                                            L'objectif de la thèse est de proposer une approche novatrice permettant de
                                            répondre aux besoins croissants de l'industrie 4.0 en combinant
                                            l'intelligence artificielle et le génie logiciel pour la modélisation et la
                                            résolution des contraintes. Le verrou scientifique consiste à identifier et
                                            caractériser les types de contraintes rencontrées dans les environnements
                                            industriels 4.0, et de proposer des modèles formels pour les représenter et
                                            les gérer selon les contextes et les profils d'usage.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            L'objectif est de proposer un nouveau cycle de production industriel
                                            combinant des techniques d'acquisition des connaissances, de génie logiciel
                                            et d'intelligence artificielle afin de répondre aux besoins évolutifs et aux
                                            contraintes d'applications industrielles, tout en prenant en compte les
                                            contextes et profils d'utilisation. L'efficacité des méthodes proposées sera
                                            validée en utilisant des études de cas industriels pour démontrer leur
                                            applicabilité et leur efficacité dans des environnements industriels réels.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglaboe5d8`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#laboe5d8`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselaboe5d8`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire DRIVE </span>
                                <small>
                                    Exploitation de Drones Aériens en Milieu confiné et maquette numérique 3D :
                                    Application à l'inspection de carrières
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`laboe5d8`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglaboe5d8`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : DRIVE.%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>DRIVE</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Exploitation de Drones Aériens en Milieu confiné et maquette numérique 3D :
                                    Application à l'inspection de carrières
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    l'ingénierie minière et géologique/ Robotique aérienne
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    Drone autonome, Intelligence Artificielle
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte, Positionnement et Objectifs de la Proposition :</span>
                                        <p>
                                            L'Inspection Générale des Carrières (IGC) est un organisme de prévention du
                                            risque, dépendant de la Mairie de Paris. Son activité d'expertise s'est
                                            étendue à l'ensemble des cavités anthropiques ou naturelles générant un
                                            aléa, ainsi que sur les problématiques liées à l'hydrogéologie. Le service
                                            assure également la mise en application des règlements d'urbanisme relatifs
                                            aux carrières. Toutefois, l'IGC se trouve confrontée à des défis persistants
                                            en termes de sécurité et d'efficacité dans ses opérations. Les méthodes
                                            traditionnelles de surveillance, principalement basées sur des inspections
                                            visuelles effectuées par le personnel, présentent des limites en termes de
                                            précision, de couverture et de sécurité. En raison de la nature dangereuse
                                            et confinée des carrières souterraines, l'exposition du personnel à des
                                            risques tels que les instabilités des cavités, les passages exigus et les
                                            déficits en oxygène est une préoccupation majeure. Face à ces défis,
                                            l'utilisation de drones civils apparaît comme une solution prometteuse pour
                                            améliorer la sécurité et l'efficacité des opérations d'inspection.
                                        </p>
                                        <p>
                                            Dans ce contexte, le projet EXDAM 3D émerge comme une solution innovante
                                            pour moderniser les pratiques de surveillance des carrières souterraines.
                                            L'objectif principal est de concevoir et développer un drone autonome
                                            capable d'explorer, de cartographier et de surveiller ces environnements de
                                            manière sécurisée et efficace, tout en réduisant la dépendance au personnel
                                            humain. Ce drone sera équipé de technologies avancées telles que des
                                            capteurs de perception, des algorithmes de planification de trajectoire et
                                            des systèmes de contrôle robustes pour naviguer de manière autonome dans des
                                            environnements complexes et variables.
                                        </p>
                                        <p>
                                            Par ailleurs, les technologies qui gravitent autour de ces drones se
                                            multiplient et se démocratisent. Néanmoins il reste des domaines où, pour
                                            des raisons technologiques, ils ne peuvent toujours pas accéder de façon
                                            totalement autonome. L'absence de signal GPS, de lumière et de signal radio
                                            continu limite leur utilisation et leur potentiel. Ce projet porte sur la
                                            réalisation d'un drone pouvant s'orienter et naviguer dans un espace confiné
                                            qui lui est totalement inconnu et fournir une maquette numérique (modèle 3D)
                                            du milieu qu'il vient de découvrir. Pour accomplir un tel objectif, nous
                                            développerons des algorithmes de planification de trajectoire et des
                                            contrôles robustes qui doivent, en présence de différentes perturbations,
                                            garantir l'intégrité du drone avec des performances acceptables.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Impact et Retombées du Projet :</span>
                                        <p>
                                            <b>Retombées Scientifiques :</b> Le projet EXDAM 3D se distingue par son
                                            approche innovante intégrant la planification de trajectoire, le
                                            contrôle/commande et la modélisation 3D des scènes complexes. Cette démarche
                                            représente une avancée significative dans les domaines de l'automatique et
                                            du pilotage, offrant des solutions robustes pour des environnements
                                            complexes. Les retombées scientifiques du projet sont de plusieurs ordres,
                                            les retombées directes et indirectes. Les retombées directes se traduiront
                                            par des articles (conférences, journaux) et brevets. Notamment, les
                                            publications porteront sur les thèmes suivants : contrôle/commande, le
                                            recalage et la modélisation 3D.
                                        </p>
                                        <p>
                                            <b>Intérêt Environnemental, Sociétal et Économique :</b> Sur le plan
                                            environnemental, le déploiement d'un drone autonome pour la surveillance des
                                            carrières souterraines permet de réduire les risques pour la santé et la
                                            sécurité du personnel, ainsi que les impacts environnementaux liés aux
                                            méthodes traditionnelles de surveillance. Sur le plan sociétal, cela
                                            contribue à améliorer les conditions de travail et la sécurité des
                                            travailleurs, tout en favorisant une meilleure gestion des infrastructures
                                            souterraines. Sur le plan économique, le projet offre des opportunités de
                                            réduction des coûts opérationnels associés à la surveillance des carrières,
                                            notamment en minimisant le besoin de ressources humaines et matérielles
                                            coûteuses.
                                        </p>
                                        <p>
                                            <b>Valorisation des résultats :</b> Ce projet pourrait faire l'objet de
                                            dépôt de brevet et donc de certaines retombées économiques. Les technologies
                                            particulières, mises au point pour ce milieu indoor, pourront être reprises
                                            dans les technologies de développement d'autres types de robots qui
                                            intéresseront d'autres secteurs des métiers de la ville (voirie, espace
                                            vert, bâtiment, urbanisme, etc.), sans compter que ce développement pourrait
                                            susciter un intérêt pour l'ensemble des concessionnaires utilisant des
                                            réseaux souterrains (Galeries techniques Orange, ENEDIS, ERDF,
                                            assainissement, etc.), voire d'autres partenaires de la ville, utilisateurs
                                            du sous-sol parisien, (RATP, SNCF, etc.).
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo168f`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo168f`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo168f`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire DRIVE </span>
                                <small>
                                    Révolutionner les réseaux 6G : un jumeau numérique basé sur l'apprentissage
                                    automatique pour des économies d'énergie
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo168f`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo168f`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : DRIVE.%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>DRIVE</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Révolutionner les réseaux 6G : un jumeau numérique basé sur l'apprentissage
                                    automatique pour des économies d'énergie
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Télécom, éditeur de logiciels de simulation, énergie
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    IA, apprentissage automatique, jumeau numérique, simulation, distillation des
                                    connaissances, apprentissage fédéré, réseaux 6G, efficacité énergétique.
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <div>
                                    <p>
                                        La technologie de jumeau numérique (en anglais, Digital Twin -DT) consiste à
                                        créer une réplication d'un composant du monde physique basée sur la simulation
                                        de son comportement et l'analyse de données collectées auprès de différentes
                                        sources (capteurs, historique enregistré, etc.). L'objectif d'un jumeau
                                        numérique est de fournir un outil fiable pour garantir la prédiction du
                                        comportement, la collaboration et la prise de décision pour les systèmes réels
                                        les plus complexes.
                                    </p>
                                    <p>
                                        Les premiers modèles de DT ont été proposés dans le secteur industriel. Ce
                                        concept gagne en popularité dans de nombreux autres domaines, tels que les
                                        réseaux de communication où l'avènement de la 6e génération de réseaux mobiles
                                        englobera de nouveaux défis à venir pertinents pour l'hétérogénéité des réseaux
                                        d'accès radio (en anglais, Radio Access Network - RAN), les topologies complexes
                                        et dynamiques et les solutions de gestion automatisées.
                                    </p>
                                    <p>
                                        Dans ce contexte, nous situons la présente thèse de doctorat qui vise à proposer
                                        des modèles de jumeaux numériques de réseau compatibles avec la 6G basée sur
                                        l'IA pour le cas d'utilisation particulier des "économies d'énergie d'un
                                        déploiement dense de cellules". Ce dernier vise une approche écologique des
                                        réseaux mobiles, qui offre une efficacité accrue et une meilleure utilisation de
                                        l'énergie en optimisant les ressources du réseau. Un outil de test Open RAN
                                        Intelligent Controller (RIC) sera utilisé conjointement avec un jumeau numérique
                                        réactif, afin d'émuler des environnements RAN complexes et des scénarios, et
                                        d'utiliser l'apprentissage automatique pour apprendre et adapter les cellules
                                        pour une meilleure utilisation de l'énergie. L'objectif du cas d'utilisation est
                                        de déterminer si le jumeau numérique apprend de manière précise et élabore les
                                        actions de suivi correctes pour améliorer l'utilisation de la puissance dans le
                                        réseau RAN.
                                    </p>
                                    <p>
                                        Par exemple, l'objectif principal de cette thèse est de proposer de nouveaux
                                        modèles basés sur l'IA (apprentissage automatique natif, basé sur les graphes ou
                                        l'apprentissage profond) principalement aux niveaux réseau et application,
                                        conformes à l'application d'économies d'énergie d'un déploiement dense de
                                        cellules. Dans le contexte des jumeaux numériques réseau, les modèles IA doivent
                                        être combinés avec des de la simulation pour permettre de prédire le
                                        comportement du réseau et faciliter la prise de décision tout en réduisant le
                                        besoin d'intervention humaine et en minimisant ainsi le risque d'erreur humaine.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo711a`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo711a`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo711a`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire DRIVE </span>
                                <small> Recherche de cibles avec des drones </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo711a`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo711a`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : DRIVE.%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>DRIVE</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>Recherche de cibles avec des drones</p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    sécurité défense, Gestion des catastrophes et des urgences
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    IA, Apprentissage automatique, Optimisation, Drones, Théorie des jeux
                                </p>

                                <span class="body-title">Bref descriptif :</span>

                                <div>
                                    <p>
                                        Le sujet de la recherche de cibles, qu'il s'agisse d'une seule cible ou de
                                        plusieurs cibles, a suscité un intérêt croissant ces dernières années,
                                        principalement en raison des avancées dans la technologie des drones (en
                                        anglais, unmanned aerial vehicle - UAV). De nombreux exemples d'application
                                        existent : suivi de criminels en fuite, localisation de personnes perdues,
                                        détection d'incendies de forêt, opérations de recherche et de sauvetage après
                                        des catastrophes naturelles, ou recherche de débris dans l'océan après un crash
                                        d'avion. Pour ces applications, il est nécessaire de disposer de stratégies de
                                        recherche résilientes et optimisées, ce qui met en lumière le domaine de la
                                        théorie de la recherche. La théorie de la recherche, l'une des disciplines
                                        originales de la recherche opérationnelle (RO), est dédiée à la production de
                                        recherches optimales.
                                    </p>
                                    <p>
                                        Le sujet de cette proposition de thèse réside dans l'utilisation des drones dans
                                        des applications militaires telles que le contrôle des frontières (par exemple,
                                        patrouiller et rechercher des trafiquants illégaux) et la recherche de cibles
                                        ennemies dans un territoire contesté. La théorie de la recherche en RO a
                                        principalement examiné le problème d'allocation de recherche (en nglais, search
                                        allocation problem - SAP), qui consiste à déterminer la distribution optimale
                                        des ressources de recherche disponibles, telles que le temps, pour maximiser les
                                        chances de détecter une cible non évadante, généralement en utilisant des
                                        trajectoires prédites. Le jeu d'allocation de recherche (en anglais, search
                                        allocation game - SAG), où la cible évite activement les chercheurs, a également
                                        été abordé dans une moindre mesure mais manque de modèles réalistes et par
                                        extension de solutions réalistes. Par exemple, l'évasion de la cible a toujours
                                        été considérée comme optimale, avec une connaissance parfaite des chercheurs et
                                        de leurs activités de recherche futures.
                                    </p>
                                    <p>
                                        Le doctorant recruté travaillera sur l'intersection du SAG et du SAP en étudiant
                                        le problème où la cible présente un comportement d'évasion sub-optimal et réagit
                                        uniquement en fonction des informations qu'elle peut recueillir auprès des
                                        chercheurs. Par exemple, la cible peut suivre des trajectoires prévisibles
                                        (dérivées du SAP) tant qu'elle n'a pas détecté un chercheur, puis utiliser des
                                        stratégies d'évasion optimales (en suivant les principes du SAG) dès qu'elle
                                        détecte la présence de chercheurs. Le segment initial de la thèse se concentrera
                                        sur la formulation et la modélisation mathématique de ce problème complexe, en
                                        l'alignant sur la littérature existante sur le SAG et le SAP. La partie suivante
                                        de la thèse portera sur le développement de méthodes de solution en RO pour
                                        atteindre des stratégies de recherche optimales. Une approche de gamification
                                        sera également étudiée. La gamification consiste à transformer un problème
                                        d'optimisation en ligne en un jeu vidéo, qui peut ensuite être abordé à l'aide
                                        de méthodes génériques d'intelligence artificielle (IA). La gamification,
                                        reconnue pour son efficacité dans les scénarios de prise de décision dynamique,
                                        semble particulièrement adaptée pour relever les défis posés par le SAG, en
                                        raison de sa nature intrinsèque de théorie des jeux.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabo711a`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo711a`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo711a`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire DRIVE </span>
                                <small>
                                    L'apprentissage automatique au service d'un réseau 5G/6G plus résilient et sécurisée
                                    à base
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo711a`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo711a`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : DRIVE.%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>DRIVE</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    L'apprentissage automatique au service d'un réseau 5G/6G plus résilient et sécurisée
                                    à base
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Télécom, sécurité informatique
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    IA, apprentissage automatique, jumeau numérique, simulation, distillation des
                                    connaissances, apprentissage fédéré, réseaux 6G, efficacité énergétique.
                                </p>

                                <span class="body-title">Bref descriptif :</span>

                                <div>
                                    <p>
                                        La prochaine technologie mobile au-delà de la cinquième génération (B5G) inclura
                                        des technologies de communication avancées ; entraînera une mobilité utilisateur
                                        accrue et garantira une connectivité efficace des appareils hétérogènes de
                                        l'Internet des objets (IdO). Ses principaux catalyseurs technologiques sont le
                                        Réseau Défini par Logiciel (en anglais, Software Defined Networks - SDN) et la
                                        Virtualisation des Fonctions Réseau (en anglais, Network Function Virtualization
                                        - NFV), le Calcul en Bordure de Réseau Mobile (en anglais, Mobile Edge Computing
                                        - MEC) et l'Informatique en Brouillard (FC). La sécurité du réseau B5G est
                                        critique en raison d'une variété de violations de sécurité qu'il présente,
                                        spécifiquement au niveau du Réseau d'Accès Radio (RAN) et du réseau cœur. Ces
                                        violations de sécurité surviennent en raison de la softwarisation et de la
                                        virtualisation des fonctions réseau, de l'hétérogénéité de la communication et
                                        de l'ouverture à d'autres réseaux (par exemple, le réseau de l'Internet des
                                        objets). Un autre facteur qui impacte la sécurité dans l'architecture B5G est le
                                        nombre croissant d'attaques cybernétiques intelligentes et complexes qui ont
                                        augmenté rapidement récemment.
                                    </p>
                                    <p>
                                        La cryptographie et les systèmes de détection d'intrusion (en anglais, Intrusion
                                        Detection System - IDS) sont deux mécanismes de sécurité majeurs. D'une part, la
                                        cryptographie est utilisée pour garantir la confidentialité des messages et
                                        l'authentification des nœuds et est utilisée pour empêcher les intrus externes
                                        de pénétrer dans le réseau. D'autre part, les IDS utilisent des agents spéciaux
                                        pour surveiller le comportement d'un nœud cible et déclencher une alarme
                                        lorsqu'une anomalie malveillante est détectée. Les IDS sont efficaces pour
                                        protéger le réseau contre les intrus internes et externes et agissent comme une
                                        deuxième ligne de défense lorsque la cryptographie est compromise. Les IDS
                                        pourraient jouer le rôle d'un système de contrôle cyber-résilient capable de
                                        reconnaître et de répondre de manière proactive aux menaces incertaines.
                                    </p>
                                    <p>
                                        Les IDS existent déjà dans les installations informatiques conventionnelles,
                                        mais leur adaptation aux réseaux B5G pose encore certaines difficultés
                                        théoriques et technologiques. Ces difficultés découlent du fait qu'avec le
                                        réseau B5G, un très grand volume de données mobiles circule à travers le réseau
                                        avec une latence de bout en bout &lt;1 ms, et il est donc difficile pour les IDS
                                        conventionnels de gérer un grand volume de données. De plus, des études
                                        actuelles ont démontré que les solutions IDS réseau existantes telles que Snort,
                                        Bro et Suricata déployées dans les réseaux câblés ne sont pas capables de gérer
                                        une énorme quantité de données, car le nombre moyen de paquets perdus livrés par
                                        ces IDS augmente rapidement lorsque la vitesse du réseau dépasse 10 Gps ou
                                        lorsque la latence est très faible. Une autre faiblesse majeure des cadres IDS
                                        conventionnels est leur incapacité à être résilients (c'est-à-dire à détecter,
                                        prévenir, résister et réagir avec précision) contre les attaques cybernétiques
                                        sophistiquées et complexes communément appelées menaces zero- day, car cette
                                        nouvelle catégorie d'attaques présente un comportement inconnu qui n'est pas
                                        défini dans la base de données de signatures d'attaques des IDS.
                                    </p>
                                    <p>
                                        D'une part, pour surmonter les problèmes de réseau et de sécurité mentionnés
                                        ci-dessus (c'est-à-dire gérer le volume énorme de données et détecter les
                                        attaques inconnues), les IDS exploitent l'utilisation d'algorithmes
                                        d'apprentissage automatique pour gérer une énorme quantité de données
                                        hétérogènes provenant de différentes sources d'information dans le but de
                                        générer automatiquement différents modèles d'attaques et donc de prédire avec
                                        précision le comportement futur des attaquants. D'autre part, pour faire face au
                                        nombre croissant d'attaques cybernétiques, la recherche en IDS se dirige vers
                                        des mécanismes plus collaboratifs et résilients tels que les IDS collaboratifs
                                        qui combinent les connaissances d'une multitude de moniteurs pour générer une
                                        image holistique du réseau surveillé.
                                    </p>
                                    <p>
                                        L'objectif principal de cette thèse de doctorat est de proposer et de développer
                                        des techniques innovantes de détection collaborative (prédiction) et de prise de
                                        décision basées sur des algorithmes d'apprentissage automatique pour protéger
                                        les composants critiques du réseau d'accès RAN 5G contre des attaques
                                        intelligentes et complexes telles que les attaquants liés à l'IA et les menaces
                                        inconnues.
                                    </p>
                                    <p>
                                        L'aspect innovant principal de cette thèse de doctorat est d'étudier la
                                        combinaison optimale entre la détection basée sur les signatures et les
                                        techniques de détection basées sur l'apprentissage automatique dans le but de
                                        tirer parti des avantages de chaque technique de détection contre les menaces
                                        inconnues et de résister aux attaques liées à l'IA. De plus, la thèse de
                                        doctorat se concentrera sur la proposition d'un nouveau mécanisme de réaction
                                        basé sur un modèle de prise de décision (par exemple, en utilisant la théorie
                                        des jeux) pour aborder le problème de la prise de décision et donc réduire
                                        encore le taux de faux positifs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglabof741`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labof741`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabof741`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire Psy-DREPI </span>
                                <small>
                                    Le chemin vers l'emploi : L'anticipation psychologique est-elle une ressource
                                    favorable ?
                                </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labof741`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabof741`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : Psy-DREPI%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>Psy-DREPI</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>
                                    Le chemin vers l'emploi : L'anticipation psychologique est-elle une ressource
                                    favorable ?
                                </p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Toutes les organisations, qu'elles soient publiques ou privées, engagées dans les
                                    parcours professionnels.
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    Anticipation psychologique, insertion socioprofessionnelle, orientation
                                    professionnelle, ressources psychologiques
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            Aujourd'hui, les acteurs du marché de l'emploi sont confrontés à une demande
                                            croissante de résultats étayés par des constatations scientifiques. Les
                                            démarches d'observatoire et/ou de labellisation permettent d'atteindre ces
                                            objectifs. Néanmoins, peu d'organismes disposent des outils nécessaires pour
                                            évaluer efficacement les différents dispositifs qu'ils déploient.
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <ol>
                                            <li>
                                                Investiguer les ressources psychosociales (freins et leviers) impliquées
                                                dans les trajectoires du retour et/ou de maintien dans l'emploi.
                                            </li>
                                            <li>
                                                Examiner les caractéristiques socio-psychologiques qui inhibent ou au
                                                contraire facilitent la projection de soi dans le processus du retour à
                                                l'emploi, en identifiant les variations selon les caractéristiques
                                                individuelles (autocensure professionnelle, représentations stéréotypées
                                                des métiers, divergences d'attente et de modèle de réussite, etc.) du
                                                public concerné.
                                            </li>
                                            <li>
                                                Concevoir un protocole innovant d'accompagnement à l'emploi visant à
                                                faciliter l'élaboration cognitive d'un “soi futur professionnel”. Via
                                                l'utilisation de casques de réalité virtuelle et de méthodes d'imagerie
                                                mentale, nous testerons dans quelle mesure ces techniques peuvent
                                                devenir des outils précieux pour aider à la construction et à la
                                                stabilisation de ressources psychologiques favorables au retour et/ou
                                                maintien dans l'emploi.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            Développer des outils d'immersion cognitive facilitant l'acquisition de
                                            ressources psychologiques favorables au retour dans l'emploi. Proposer des
                                            accompagnements permettant de lutter contre l'auto-censure professionnelle
                                            et les stéréotypes associés aux métiers.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item">
                        <h2 class="accordion-header" v-bind:id="`headinglaboc09e`">
                            <button
                                class="accordion-button collapsed d-flex align-items-start flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#laboc09e`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselaboc09e`"
                            >
                                <span class="mb-2 text-green-light"> Laboratoire ICMUB </span>
                                <small> Conception de (photo)catalyseurs supportés pour la technologie en flux </small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`laboc09e`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglaboc09e`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : ICMUB%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoire :</span>
                                <p>Laboratoire ICMUB</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>Conception de (photo)catalyseurs supportés pour la technologie en flux</p>

                                <p>
                                    <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                    Pharmaceutique, peintures, composites, colorants, cosmétique, énergie,
                                    chimie/catalyse, ciments, nanomédecine, usinage mécanique…
                                </p>

                                <p>
                                    <span class="body-title">Mots-clefs :</span>
                                    (Nano)Toxicologie caractérisations, quantification, nanoparticules, nanocomposites,
                                    poudres
                                </p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>
                                            L'industrie chimique est un pilier de l'économie mondiale, mais elle a
                                            mauvaise réputation à cause de l'énergie qu'elle consomme et des déchets
                                            qu'elle génère. Afin de développer des procédés de synthèse plus
                                            éco-compatibles, les chimistes ont développé le concept de catalyse. Cette
                                            révolution a permis d'accélérer les réactions mais aussi d'accéder à de
                                            nouveaux procédés de synthèse. Aujourd'hui, ils représentent
                                            <b>80% des procédés de fabrication de l'industrie chimique.</b> Plus
                                            récemment, un intérêt croissant est porté à un nouveau concept de
                                            catalyseurs inspirés de la photosynthèse : <b>les photocatalyseurs</b>.
                                            Ceux-ci vont « absorber » l'énergie de la lumière et la transmettre aux
                                            réactions chimiques. Son intérêt pour l'industrie chimique n'est plus à
                                            démontrer. Pourtant, son utilisation réelle à l'échelle industrielle est
                                            freinée par plusieurs facteurs : <b>prix élevé</b> des photocatalyseurs,
                                            <b>problème de recyclage</b> et de <b>purification</b> des catalyseurs,
                                            absorption dans le bleu (longueur d'onde courte induisant des
                                            <b>dégradations et limitant la montée en échelle</b>)…
                                        </p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <ul>
                                            <li>
                                                <b
                                                    >Mise au point de photocatalyseurs robustes absorbant sur tout le
                                                    spectre du visible</b
                                                >
                                                : concevoir des molécules stables chimiquement et à l'irradiation
                                                lumineuse et rapides à synthétiser. L'absorption sur tout le spectre
                                                visible permettra une augmentation d'échelle d'un facteur environ 250
                                                par rapport au bleu et limitera les réactions parasites liées aux
                                                dégradations.
                                            </li>
                                            <li>
                                                <b>Mise au point de photocatalyseurs supportés</b> : rendre les
                                                photocatalyseus greffables sur des supports afin de simplifier leur
                                                recyclage et limiter les étapes de purification.
                                            </li>
                                            <li>
                                                <b>Utilisation de systèmes moins coûteux et moins toxiques</b> : limiter
                                                les matières coûteuses et toxiques, le nombre d'étapes de synthèse et
                                                rendre possible une réutilisation multiple du système.
                                            </li>
                                            <li>
                                                <b>Application aux procédés de synthèse de l'industriel partenaire </b>:
                                                permettre d'optimiser un procédé existant ou en cours de mise en place
                                                propre au partenaire.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>
                                            Ce projet a pour but de
                                            <b
                                                >lever les différents freins à l'utilisation de la photocatalyse dans
                                                l'industrie</b
                                            >
                                            en concevant un photocatalyseur possédant les propriétés suivantes :
                                        </p>
                                        <ul>
                                            <li>
                                                Absorbant sur tout le spectre du visible :
                                                <b>limitant la dégradation</b> des molécules mais aussi permettant une
                                                <b>meilleure pénétration de la lumière dans le milieu réactionnel</b> et
                                                permettant la <b>montée en échelle.</b>
                                            </li>
                                            <li>
                                                Supportés : <b>diminuant les coûts</b> de l'utilisation et permettant
                                                <b>son recyclage</b>
                                            </li>
                                            <li>
                                                Utilisable en <b>flux continu</b> : favorisant la montée en échelle et
                                                facilitant l'incorporation dans un processus intégré.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <router-link to="/" class="btn btn-primary mt-4 px-3 py-2 text-uppercase"> Retour à l'accueil </router-link>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            mail_body: `Nom :%0D%0APrénom :%0D%0AFonction :%0D%0AMail :%0D%0ATel :%0D%0AMessage :`,
        };
    },
};
</script>
