<template>
    <div id="app">
        <div v-if="dev_mode" class="position-fixed" style="top: 10px; left: 10px; z-index: 9999">
            <span class="badge bg-primary d-block d-sm-none">xs</span>
            <span class="badge bg-secondary d-none d-sm-block d-md-none">sm</span>
            <span class="badge bg-success d-none d-md-block d-lg-none">md</span>
            <span class="badge bg-dark d-none d-lg-block d-xl-none">lg</span>
            <span class="badge bg-info d-none d-xl-block">xl</span>
        </div>

        <router-view ref="view" class="flex-fill body-container"></router-view>

        <footer class="px-5">
            <div class="row no-gutters mx-0 my-3">
                <div class="col small">
                    Créé par
                    <a
                        target="_blank"
                        href="https://www.agence-evenementielle-innovevents.fr/reseau-evenementiel/dijon/"
                    >
                        INNOV'events Dijon
                    </a>
                    ©{{ new Date().getFullYear() }}
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dev_mode: process.env.NODE_ENV == "development",
        };
    },
};
</script>
