import { render, staticRenderFns } from "./DepotProblematiqueView.vue?vue&type=template&id=4bc318c2&scoped=true&"
import script from "./DepotProblematiqueView.vue?vue&type=script&lang=js&"
export * from "./DepotProblematiqueView.vue?vue&type=script&lang=js&"
import style0 from "./DepotProblematiqueView.vue?vue&type=style&index=0&id=4bc318c2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc318c2",
  null
  
)

export default component.exports