<template>
    <div class="mt-3" id="accueil">
        <div
            v-if="isFromTY"
            class="alert alert-success container-lg text-white d-flex align-items-center rounded-1"
            role="alert"
        >
            <i class="fa-solid fa-check me-3"></i>
            <div>Merci pour votre inscription, un email de confirmation vous a été envoyé.</div>
        </div>

        <div
            v-if="isFromDepot"
            class="alert alert-green-dark container-lg text-white d-flex align-items-center rounded-1"
            role="alert"
        >
            <i class="fa-solid fa-check me-3"></i>
            <div>Merci pour votre contribution, votre saisie à bien été enregistrée.</div>
        </div>
        <AccueilView ref="home" id="home" class="container-lg" />
        <ProgrammeView ref="programme" id="programme" class="my-4" />
        <AccesView ref="acces" id="acces" />

        <router-link id="gototop" to="/#" class="btn btn-primary btn-lg border-primary-light rounded-circle">
            <div class="d-flex-center h-100">
                <i class="fa-solid fa-chevron-up my-auto"></i>
            </div>
        </router-link>
    </div>
</template>

<script>
import AccueilView from "./_AccueilView.vue";
import ProgrammeView from "./_ProgrammeView.vue";
import AccesView from "./_AccesView.vue";

export default {
    components: {
        AccueilView,
        ProgrammeView,
        AccesView,
    },
    data() {
        let _isFromTY = localStorage.fromty && JSON.parse(localStorage.fromty);
        localStorage.fromty = false;

        let _isFromDepot = localStorage.fromDepot && JSON.parse(localStorage.fromDepot);
        localStorage.fromDepot = false;

        return {
            isFromTY: _isFromTY,
            isFromDepot: _isFromDepot,
        };
    },
    mounted() {
        let btnGoToTop = document.getElementById("gototop");

        window.onscroll = scrollFunction;

        function scrollFunction() {
            if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
                btnGoToTop.style.display = "block";
            } else {
                btnGoToTop.style.display = "none";
            }
        }
        scrollFunction();
    },
};
</script>
